import React from "react"
import { Grid } from "semantic-ui-react"
import Social from "./social"

const Footer = () => (
  <Grid
    verticalAlign="middle"
    style={{
      backgroundColor: "#74005F",
      marginTop: "50px",
    }}
  >
    <Grid.Row style={{ paddingBottom: "1rem" }}>
      <Grid.Column
        textAlign="center"
        width={7}
        style={{
          backgroundColor: "#74005F",
          color: "white",
          marginLeft: "30px",
        }}
      >
        <h5>© {new Date().getFullYear()} GlobalConnect</h5>
      </Grid.Column>
      <Grid.Column
        width={7}
        textAlign="center"
        style={{ alignItems: "center" }}
      >
        <Social />
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

export default Footer

import React, { Component } from "react"
import { Container } from "semantic-ui-react"
import { connect } from "react-redux"
import "./navbar.css"
import { withBreakpoints } from "gatsby-plugin-breakpoints"
import { changeLANG } from "../state/store"
import Mobile from "./navbar-mobile"
import Desktop from "./navbar-desktop"

class NavBar extends Component {
  render() {
    const { breakpoints, list, lang, page, children } = this.props

    return breakpoints.sm ? (
      <div style={{ minHeight: "100vh" }}>
        <Mobile page={page} list={list} lang={lang} updLang={changeLANG} />
        <Container style={{ marginTop: "2em" }}>{children}</Container>
      </div>
    ) : (
      <div style={{ minHeight: "100vh" }}>
        <Desktop lang={lang} updLang={changeLANG} page={page} display />
        <Container style={{ marginTop: "2em" }}>{children}</Container>
      </div>
    )
  }
}

const WithBreaks = withBreakpoints(NavBar)

export default connect(
  state => ({
    lang: state.lang,
    page: state.page,
  }),
  null
)(WithBreaks)

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { connect } from "react-redux"
import { changeLANG, updPAGE } from "../state/store"
import "./layout.css"
import NavBar from "./navbar"
import Footer from "./footer"

const menuList = {
  home: { eng: "About Us", esp: "Sobre Nostros" },
  products: { eng: "Products", esp: "Productos" },
  support: { eng: "Support", esp: "Soporte" },
  contact: { eng: "Contact", esp: "Contacto" },
}

class Layout extends React.Component {
  setLang(country) {
    country === "US"
      ? this.props.dispatch(changeLANG("english"))
      : this.props.dispatch(changeLANG("spanish"))
  }

  componentDidMount() {
    const arrLoc = window.location.href.split("/")
    console.log("location", arrLoc[arrLoc.length - 2])
    this.props.dispatch(updPAGE(arrLoc[arrLoc.length - 2]))
    if (this.props.lang === "none") {
      if (window.navigator.geolocation) {
        // window.navigator.geolocation.getCurrentPosition(this.locate)
      }
    }
  }

  locate = position => {
    var lat = position.coords.latitude
    var lon = position.coords.longitude
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${process.env.GATSBY_GOOGLE}`
    )
      .then(res => {
        return res.json()
      })
      .then(res => {
        console.log("res", res)
        this.setLang(res.results[0].address_components[6].short_name)
      })
  }

  render() {
    const { lang } = this.props

    return (
      <>
        <NavBar list={menuList}>
          <div
            style={{
              // margin: `0 auto`,
              // maxWidth: 960,
              // padding: `0 1.0875rem 1.45rem`,
              zIndex: 1000,
            }}
          >
            <main style={{ position: "relative", zIndex: "1" }}>
              {this.props.children}
            </main>
          </div>
        </NavBar>
        <Footer />
      </>
    )
  }
}
export default connect(
  state => ({
    lang: state.lang,
    page: state.page,
  }),
  null
)(Layout)

// export const data = graphql`
//   query SiteTitleQuery {
//     site: site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `

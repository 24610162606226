import React from "react"
import { Button } from "semantic-ui-react"

const ButtonSocialEx = () => (
  <div style={{}}>
    <Button size="tiny" circular color="facebook" icon="facebook" />
    <Button size="tiny" circular color="twitter" icon="twitter" />
    <Button size="tiny" circular color="linkedin" icon="linkedin" />
    <Button size="tiny" circular color="google plus" icon="google plus" />
  </div>
)

export default ButtonSocialEx

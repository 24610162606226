import React from "react"
import { Grid, Menu, Button } from "semantic-ui-react"
import { Link } from "gatsby"
import Logo from "./image"
import { connect } from "react-redux"
import { changeLANG } from "../state/store"

class NavBarDesktop extends React.Component {
  render() {
    const { lang, page, display } = this.props
    return (
      <div
        style={{
          marginTop: `.3rem`,
          position: "sticky",
          top: "0px",
          zIndex: "3",
          backgroundColor: "white",
          display: `${display ? "block" : "none"}`,
        }}
      >
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Row style={{ paddingBottom: "0rem" }}>
            <Grid.Column width={4}>
              <Link to="/">
                <div
                  style={{
                    minWidth: "50px",
                    maxWidth: `200px`,
                    margin: "auto",
                  }}
                >
                  <Logo />
                </div>
              </Link>
            </Grid.Column>
            <Grid.Column textAlign="center" width={8}>
              <h4 style={{ color: "black" }}>
                {lang !== "english"
                  ? "¡Conecta tus emociones!"
                  : "Connect your emotions!"}
              </h4>
            </Grid.Column>
            <Grid.Column width={4}>
              <Button
                style={{
                  color: "white",
                  backgroundColor: "black",
                }}
                size="tiny"
                onClick={() =>
                  lang === "english"
                    ? this.props.dispatch(changeLANG("spanish"))
                    : this.props.dispatch(changeLANG("english"))
                }
              >
                {lang === "english" ? "Español" : "English"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Menu
          style={{
            backgroundColor: "#74005F",
            justifyContent: "center",
            alignItems: "center",
          }}
          inverted
          pointing
          secondary
        >
          {/* <Menu.Item
            as={Link}
            to="/"
            name={lang === "english" ? "About Us" : "Sobre Nosotros"}
            active={page === "" || page === "localhost:8000"}
          /> */}
          <Menu.Item
            as={Link}
            to="/about/"
            name={lang === "english" ? "About Us" : "Sobre Nosotros"}
            active={page === "about"}
          />
          <Menu.Item
            as={Link}
            to="/products/"
            active={page === "products"}
            name={lang === "english" ? "Products" : "Productos"}
          />

          <Menu.Item
            as={Link}
            to="/support/"
            name={lang === "english" ? "Tech Support" : "Soporte Técnico"}
            active={page === "support"}
          />
          <Menu.Item
            as={Link}
            name={lang === "english" ? "Contact" : "Contacto"}
            to="/contact/"
            active={page === "contact"}
          />
          {/* <Menu.Menu position='right'>
            <Menu.Item>
              <Input icon='search' placeholder={lang === 'english'? 'Search...' : 'Buscar...'} />
            </Menu.Item>
          </Menu.Menu> */}
        </Menu>
      </div>
    )
  }
}

export default connect(
  state => ({
    lang: state.lang,
    page: state.page,
  }),
  null
)(NavBarDesktop)

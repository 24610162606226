import React from "react"
import { Menu, Grid, Button, Dropdown } from "semantic-ui-react"
import { Link } from "gatsby"
import Logo from "./image"
import { connect } from "react-redux"
import { changeLANG } from "../state/store"

class NavBarMobile extends React.Component {
  render() {
    const { page, lang, list } = this.props

    return (
      <div
        style={{
          paddingTop: `1rem`,
          marginBottom: `.5rem`,
          position: "sticky",
          top: "0px",
          zIndex: "2",
          backgroundColor: "white",
        }}
      >
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={8}>
              <div style={{ margin: "auto", maxWidth: `75px` }}>
                <Logo />
              </div>
            </Grid.Column>

            <Grid.Column
              style={{
                margin: "auto",
              }}
              width={8}
            >
              <Button
                size="tiny"
                style={{
                  maxWidth: "100px",
                  color: "white",
                  backgroundColor: "#74005F",
                }}
                onClick={() =>
                  lang === "english"
                    ? this.props.dispatch(changeLANG("spanish"))
                    : this.props.dispatch(changeLANG("english"))
                }
              >
                {lang === "english" ? "Español" : "English"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={2}>
          <Grid.Row style={{ paddingBottom: "0px", paddingTop: "0px" }}>
            <Grid.Column
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#74005F",
              }}
            >
              <Menu
                style={{
                  backgroundColor: "#74005F",
                  maxWidth: "100px",
                }}
                inverted
                compact
              >
                <Dropdown
                  style={{ fontWeight: "bold" }}
                  text={lang === "english" ? "Menu" : "Menú"}
                  floating
                  className="link item"
                >
                  <Dropdown.Menu>
                    <Menu.Item as={Link} to="/" name="About Us">
                      {lang === "english" ? "About Us" : "Sobre Nosotros"}
                    </Menu.Item>
                    <Dropdown.Item>
                      <Dropdown
                        pointing="left"
                        text={lang === "english" ? "Products" : "Productos"}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item as={Link} to="/products/">
                            {lang === "english"
                              ? "All Products"
                              : "Todos Productos"}
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item as={Link} to="/products/#7wifi">
                            P13 7’’ WIFI
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to="/products/#73g">
                            P13 7’’ 3G
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to="/products/#kids">
                            13 7’’ KIDS WIFI
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to="/products/#10wifi">
                            P13 10´´ WiFi
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/support/">
                      {lang === "english" ? "Support" : "Soporte"}
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/contact/">
                      {lang === "english" ? "Contact" : "Contacto"}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>
            </Grid.Column>
            <Grid.Column
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#74005F",
              }}
            >
              <Menu
                style={{ backgroundColor: "#74005F" }}
                inverted
                secondary
                pointing
              >
                <Menu.Item
                  as={Link}
                  to={Object.keys(list).includes(page) ? `/${page}/` : "/"}
                  name={
                    Object.keys(list).includes(page)
                      ? lang === "english"
                        ? list[page].eng
                        : list[page].esp
                      : lang === "english"
                      ? list.home.eng
                      : list.home.esp
                  }
                  active
                />
              </Menu>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

export default connect(
  state => ({
    lang: state.lang,
    page: state.page,
  }),
  null
)(NavBarMobile)
